<template>
  <div class="container">
    <navigation msg="我要回评"></navigation>
    <div>
      <!-- 回评内容 -->
      <div class="title">回评内容</div>
      <textarea
        v-model="recomment"
        maxlength="300"
        rows="10"
        placeholder="请输入您要回评的内容，字符数载300个字符以内"
      ></textarea>
      <!-- 好评 -->
      
        <div class="list2">
          <div
            class="item2"
            v-for="(item1, index1) in list"
            :key="index1"
            @click="addTag(item1)"
          >
            {{ item1.label_name }}
          </div>
         
      </div>

      <!-- 提交 -->
      <div class="submit" @click="submit">提交</div>
    </div>
  </div>
</template>
<script>
import navigation from "../../components/navigation/index";
import { Toast } from "vant";
export default {
  data() {
    return {
      recomment: "",
      id:"",
      list:[]
  
    };
  },
  components: {
    navigation,
  },
  methods: {
    addTag(item) {
      this.recomment += `${item.label_name},`
    },
    submit() {
      // 回评内容
      let recomment = this.recomment;
      let id = this.id
      // console.log(id)
      if (recomment == "") {
        Toast("请输入回评内容");
        return;
      }
      // console.log(id,recomment)
      this.$http
        .post("/user/v1/pro_files/replyAdd", {
          reqType: "replyAdd",
          evaluation_id: id,
          content: recomment,
          label_type: '',
          label_desc: '',
          comment_set: 1,
        })
        .then((res) => {
          let response = JSON.parse(res.data);
          if (response.code == 0) {
            Toast.success("保存成功");
            this.recomment = ""
            this.$router.go(-1)
          } else {
            Toast.fail(response.mag);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    let id = this.$route.query.id
    this.id = id
    // console.log(id)
    // 获取评价标签
    this.$http.post("/user/v1/pro_files/replyLabel",{reqType:"replyLabel"})
    .then((res)=>{
      let response = JSON.parse(res.data)
       let list1 = response.data.praise
       this.list = list1
    }).catch((e)=>{console.log(e)})
  },
};
</script>
<style scoped>
.selectList {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0.55rem;
}
.selectItem {
  height: 0.48rem;
  line-height: 0.48rem;
  padding: 0.05rem 0.3rem;
  background: #ffede4;
  border-radius: 0.4rem;
  color: #ff4900;
  position: relative;
  margin-right: 0.2rem;
  margin-top: 0.2rem;
}
.selectItem img {
  width: 0.3rem;
  height: 0.3rem;
  position: absolute;
  top: -0.1rem;
  right: -0.1rem;
}
.submit {
  width: 7.02rem;
  height: 0.97rem;
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  border-radius: 0.16rem;
  line-height: 0.97rem;
  text-align: center;
  color: #fff;
  margin: 0 auto;
  margin-top: 1rem;
  font-size: 0.3rem;
}
.warn {
  background-color: #fff;
  height: 0.33rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff4900;
  line-height: 0.33rem;
  padding: 0.55rem;
}
.commentSet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.list2 {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 0 0.55rem;
}
.item2 {
  
    height: 0.79rem;
    background: #FFFFFF;
    border-radius: 0.4rem;
    border: 0.02rem solid #979797;
    line-height:  0.79rem;
    padding:0 0.5rem;
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    margin-right:0.2rem;
    margin-top:0.2rem;
}
.title {
  box-sizing: border-box;
  /* width:100%; */
  background-color: #fff;
  padding: 0.2rem 0.48rem;
  font-size: 0.42rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
textarea {
  box-sizing: border-box;
  border: none;
  resize: none;
  padding: 0.33rem 0.48rem;
  width: 100%;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6f6f6f;
}
.container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #fff;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>